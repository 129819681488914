import styled from 'styled-components';
// const red = '#74c0f2'
// 202107
// const red = '#B52619'
// 20210911
// const red = '#012abf'
// 20210919
// const red = '#f4aa3d'
// 20210923
// const red = '#f7c542'
// 20211108
// const red = '#e0e0ef'
// 20220101
// const red = '#f3a978'
// 20220111
// const red = '#237db2'
// 20220111
// const red = '#5cae41'
// 20220520
// const red = '#276662'
// 20220610
// const red = '#007451'
// 20220810 秋季
// const red = '#ffa440'
// 20221104
// const red = '#de2710'
// 20220130
// const red = '#cc4d38'
// 20210930
// const red = '#ba2320'
// const fontColor = '#ffa440'
// const fontColor="#ffc06b"
//202109
// const headerFontColor = '#70c1f8'
//20211108
//2023.02.06
// 换肤-颜色
const red = '#8b1b0d '
const iconColor="#8b1b0d "
// 移动端导航字体颜色
const fontColor="#8b1b0d "
// PC端导航字体颜色
const headerFontColor = '#fff'
// const headerFontColor = '#214061'
export const HeaderWarpPc = styled.div `
    @media(min-width:750px){
        width:100%;
        /* height:160px; */
        // height:380px;
        // 换肤-PC端-头部背景
        // background:url(/img/skin/header_2024_09_27.png) center top no-repeat;
        // background-size: 100% 100%;
        // background-size:cover;
        overflow:hidden;
        position: relative;
        .header_bg{
            width:100%;
            height:auto;
            margin-bottom:60px;
        }
        .header{
            width:1240px;
            height:50px;
            margin:30px auto 20px;
            display:flex;
            align-items:flex-end;
            flex-direction:row;
            position: absolute;
            bottom:60px;
            left:50%;
            transform:translate(-50%,0);
            .logo{
                width: 156px;
                img{
                    width:100%;
                }
            }
            .is_open_message{
                width:320px;
                display:flex;
                flex-direction:row;
                align-items:center;
                margin-left:40px;
            }
            .today,.tommorow{
                padding:0 12px;
                text-align:center;
            }
            .search{
                width:385px;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                margin-left: 200px;
                margin-right:50px;
                align-items: center;
                input{
                    width:350px;
                    height:26px;
                    /* border:1px solid #009cbe; */
                    border:2px solid ${iconColor};
                    border-radius:12px;
                    outline:none;
                    text-indent:20px;
                }
                .search_btn{
                    width:20px;
                    height:20px;
                    cursor:pointer;
                    display: flex;
                    align-items:center;
                    margin-left: 5px;
                    svg{
                        width:100%;
                    }
                    img{
                        width:100%;
                    }
                }
            }
            .en{
                    color:#3a4045;
                    font-size:14px;
                    line-height:26px;
                    margin-left:14px;
            }
            .hdbm{
                width:114px;
                margin-left: 0px;
                // height:26px;
                height:32px;
                background:#fff;
                border-radius:13px;
                display: flex;
                flex-direction:row;
                align-items:center;
                justify-content:center;
                color:${iconColor};
                border:2px solid ${iconColor};
                font-size:18px;
                svg{
                    width:20px;
                }
                img{
                    width:114px;
                }
            }
            .hdbm02{
                width:214px;
                margin-left:10px;
                // height:26px;
                height:32px;
                background:#fff;
                border-radius:13px;
                display: flex;
                flex-direction:row;
                align-items:center;
                justify-content:center;
                color:${iconColor};
                border:2px solid ${iconColor};
                font-size:18px;
            }
            .lang-box{
                width:116px;
                height:32px;
                line-height:32px;
                border-radius:16px;
                background-color:${iconColor};
                color:#FFFFFF;
                margin-right:10px;      
                margin-left:10px;
                font-size:18px;
                display: flex;
                text-align:center;
                
                span{
                    width:58px;
                    height:100%;
                }
                
                .mpyd{
                    width:58px;
                    height:100%;
                    background-color:#FFFFFF;
                    color: ${iconColor};
                    border-top-right-radius:16px;
                    border-bottom-right-radius:16px;
                }
            }
            .mpyd-bak{
                width:22px;
                margin-left:10px;
                height:26px;
                display: flex;
                flex-direction:row;
                align-items:center;
                justify-content:center;
                font-size:18px;
                margin-right:10px;
                svg{
                    width:20px;
                }
                img{
                    width:22px;
                }          
            }
        }
        // 换肤-头部-导航背景色
        .nav_bg{
            width:100%;
            height:60px;
            background: #8b1b0d ;
            // background: url(/img/skin/nav-bg.jpg) repeat-x center 0;
            background-size: auto 60px;
            position: absolute;
            bottom:0;
            .nav{
                width:1240px;
                margin:0 auto;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                .headerNav{
                    font-size:20px;
                    height:60px;
                    text-align:center;
                    color:${headerFontColor};
                    font-weight:bold;
                    line-height:60px;
                }
                .selected{
                    border-bottom:5px solid ${headerFontColor};
                }
            }
        }
    }
    @media(max-width:750px){
        display: none;
    }
`
export const HeaderWarpM = styled.div `
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        /** 20210517 taoZi 去掉，按照要求，顶部不需要固定 **/
        /* position:fixed; */
        /* left:0; */
        /* top:0; */
        /* z-index:100; */
        .addny{
            width:100%;
            img{
                width:100%;
            }
        }
        .header{
            margin:0 auto;
            height:1.37rem;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            align-items:center;       
            padding:0 .2rem;
            background:#fff;   
            box-sizing:border-box;
            border-bottom:1px solid #e5e5e5;
            .logo{
                width:2.28rem;
                img{
                    width:100%;
                }
            }
            .search{
                width:2.3rem;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                height:24px;
                /* border:1px solid #009cbe; */
                border:1px solid ${red};
                border-radius:12px;
                align-items:center;
                input{
                    width:1.6rem;
                    height:20px;
                    outline:none;
                    border:none;
                    margin-left:.1rem;
                    background:none;
                }
                .search_btn{
                    width:20px;
                    height:20px;
                    margin-right:.1rem;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    img{
                        width:20px;
                        height:20px;
                    }
                    svg{
                        width:20px;
                    }
                }
            }
            .is_open_message{
                width:2.3rem;
                display:flex;
                flex-direction:column;
                font-size:.22rem;
                line-height:.3rem;
                color:#174065;
                text-align:left;
            }
            .en{
                    color:#1c4468;
                    font-size:.2rem;
            }
            .menu_btn{
                width:.41rem;
                img{
                    width:100%;
                }
            }
        }
        .nav_bg{
            /** 20210524 add start - taoZi **/
            position: absolute;
            z-index: 2;
            right: 0;
            /** 20210524 add end - taoZi **/
            width:2.36rem;
            /* background:rgba(14,157,158,.8); */
            background: #fff;
            overflow:hidden;
            float:right;
            margin:.12rem;
            border-radius:.07rem;
            .nav{
                width:100%;
                display:flex;
                flex-direction:column;
                padding-bottom:.5rem;
                
                .headerNav{
                    line-height:.76rem;
                    font-size:.34rem;
                    text-align:center;
                    color: ${fontColor};
                    border-bottom:0.02rem solid ${headerFontColor};
                    margin:0 auto;
                }
                .selected{
                    font-weight:bold;
                    border-bottom:0.05rem solid ${fontColor};
                }
            }
        }
    }
    @media(min-width:750px){
        display: none;
    }
`
