import React from 'react';
import {withRouter} from 'react-router-dom';
import {XnmyWrap} from './style'
import { Skeleton } from 'antd';

class Xnmy extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            tablist:null,
            list:null,
            tab_1_index:0,
            tab_2_index:0,
            current_termid:null
        }
        this.changeTab_1 = this.changeTab_1.bind(this)
        this.changeTab_2 = this.changeTab_2.bind(this)
    }
    componentDidMount(){
        document.title = '3D游场馆'
        this.initTab()
        this.goTo()
    }
    initTab(){
        React.$request({
            url:'Portal/Index/is_ajax_classify',
            method:'get',
            params: {
                term_id: 120
            }
        }).then(res=>{
            this.setState({
                tablist:res.data
            })
            this.initVideo(res.data[0].term_id)
        })
    }
    initVideo(id){
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type:3,
                termid: id
            }
        }).then(res=>{
            this.setState({
                list:res.data
            })
        }) 
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    changeTab_1(index,id){
        this.initVideo(id)
        this.setState({
            tab_1_index:index,
            tab_2_index:0
        })
    }
    changeTab_2(index){
        this.setState({
            tab_2_index:index
        })
    }
    render(){
        return(
            this.state.list?
            <XnmyWrap>
                <div className="tabWrap">
                    {
                        this.state.tablist.map((item,index)=>{
                            return(
                                <div className={this.state.tab_1_index === index ?'tab tabActive':'tab'} onClick={()=>this.changeTab_1(index,item.term_id)} key={item.term_id}>
                                    {item.name}
                                </div>
                            )
                        })
                    }
                </div>
                <div className="tabSwrap">
                    {
                        this.state.list.map((item,index)=>{
                            return(
                                <div className={this.state.tab_2_index === index ?'tabS tabSactive':'tabS'} onClick={()=>this.changeTab_2(index)} key={item.id}>
                                    {item.post_title}
                                </div>
                            )
                        })
                    }
                </div>
                <div className="videoWrap">
                    <video src={this.state.list[this.state.tab_2_index].video} poster={this.state.list[this.state.tab_2_index].path} autoPlay={true} loop=
                {true} controls={true}></video>
                </div>
            </XnmyWrap>
            :<Skeleton active />
        )
    }
}
export default withRouter(Xnmy)
