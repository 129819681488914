import React from 'react';

import Zxgk from './component/zxgk'
import Gzzc from './component/gzzc'
import Zzjg from './component/zzjg'
import Bjkxzxtx from './component/bjkxzxtx'
import Tzgg from './component/tzgg'
import Hdyg from './component/hdyg'
import Xwzx from './component/xwzx'
import LibraryDetail from './component/librarydetail'
import Xnmy from './component/xnmy'

import {NavLink,Switch,Route,Redirect} from 'react-router-dom'
import { actions } from './store'

import {LibraryHeader,LibraryNav,LibraryRoute,LibraryWrap} from './style'

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';




class Library extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            bannerUrl:null
        }
    }
    componentDidMount(){
        document.title = '本馆信息'
        this.props.get_Banner()
        this.goTo()
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    render(){
        return(
            <LibraryWrap>
                <LibraryHeader style={{"backgroundImage":"url("+this.props.bannerUrl+")"}}>
                </LibraryHeader>
                <div className="library_content">
                    <LibraryNav>
                        <div>
                            <NavLink className='library_nav_link' activeClassName='library_nav_link_active' to='/library/zxgk'>中心概况</NavLink>
                            {/* <NavLink className='library_nav_link' activeClassName='library_nav_link_active' to='/library/gzzc'>馆长致辞</NavLink> */}
                            <NavLink className='library_nav_link' activeClassName='library_nav_link_active' to='/library/zzjg'>组织架构</NavLink>
                            <NavLink className='library_nav_link' activeClassName='library_nav_link_active' to='/library/xnmy'>3D游场馆</NavLink>
                            <NavLink className='library_nav_link' activeClassName='library_nav_link_active' to='/library/bjkxzxtx'>北京科学中心体系</NavLink>
                            <NavLink className='library_nav_link' activeClassName='library_nav_link_active' to='/library/tzgg'>通知公告</NavLink>
                            <NavLink className='library_nav_link' activeClassName='library_nav_link_active' to='/library/xwzx'>新闻资讯</NavLink>
                            <NavLink className='library_nav_link' activeClassName='library_nav_link_active' to='/library/hdyg'>活动预告</NavLink>
                        </div>
                    </LibraryNav>
                    <LibraryRoute>
                        <Switch>
                                {/* <Route exact path='/library' component={Zxgk} /> */}
                                <Route path='/library/zxgk' component={Zxgk}/>
                                <Route path='/library/gzzc' component={Gzzc}/>
                                <Route path='/library/zzjg' component={Zzjg}/>
                                <Route path='/library/bjkxzxtx' component={Bjkxzxtx}/>
                                <Route path='/library/tzgg' component={Tzgg}/>
                                <Route path='/library/hdyg' component={Hdyg}/>
                                <Route path='/library/xwzx' component={Xwzx}/>
                                <Route path='/library/detail/:id' component={LibraryDetail}/>
                                <Route path='/library/xnmy' component={Xnmy}/>
                                <Redirect from="/library*" to="/library/zxgk" />
                        </Switch>
                    </LibraryRoute>
                </div>
            </LibraryWrap>
        )
    }
}
const mapStateToProps = (state)=>{
    return{
        bannerUrl:state.getIn(['library','headerBanner'])
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        get_Banner(){
            dispatch(actions.getBgxxBanner())
        }
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Library))
